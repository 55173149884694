import axios from 'axios';

// export const API_URL = 'http://localhost:8080';
export const API_URL = 'https://api.play.kevinkuhn.ch';
export const API_AUTH_TOKEN = 'k5UBnMmkKyjT3cBGF7KEjvWdRHQsbV2N';
const instance = axios.create({
    baseURL: API_URL
});

instance.defaults.headers.common["Auth-Token"] = API_AUTH_TOKEN;
export default instance;