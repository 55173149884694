import Close from '../assets/close.svg'
import externallinkicon from '../assets/externallinkicon.svg'
import WordLimit from "react-word-limit";
import Modal from "react-bootstrap/Modal";
import {useState} from "react";

const ItemDetails = ({setShowDetails, loadItem, item, mostSimilar, topics}) => {
    const [showDescription, setShowDescription] = useState(false)
    const [showMostSimilar, setShowMostSimilar] = useState(false)

    return (
        <div className="location_details_card">
            <div className="row" style={{justifyContent: 'space-between'}}>
                <div className="col-10 loaction_card_tags">
                    <span>{item.category}</span>
                </div>
                <div className="col-2 loaction_card_tags" onClick={() => setShowDetails(false)}
                     style={{cursor: "pointer"}}>
                    <img src={Close} alt="close"/>
                </div>
            </div>

            <h3>
                {item.title}
                <a href={item.url} target={"_blank"}>
                    <img src={externallinkicon} alt="externallinkicon"/>
                </a>
            </h3>
            <p>
                <WordLimit limit={300}>{item.description}</WordLimit>
                <button className={"btn btn-sm btn-link"} onClick={() => setShowDescription(true)}>
                    Mehr Anzeigen
                </button>
            </p>

            {topics != null &&
            <div>
                <h3>Topics</h3>
                <img className={"img-fluid"} src={`data:image/jpeg;base64,${topics}`}/>
            </div>
            }
            <h3>Ähnlichste Einträge</h3>
            <ul>
                {mostSimilar != null && mostSimilar.slice(0, 5).map((e) => {
                    return (
                        <li key={e.id}>
                            <a href={"#"}
                               onClick={() => loadItem(e.id)}>{e.title}, {e.category} ({Math.round(e.similarity * 100)}%)</a>
                        </li>
                    )
                })}
            </ul>
            {mostSimilar != null &&
            <button className={"btn btn-sm btn-link"}
                    onClick={() => setShowMostSimilar(true)}>
                Mehr Anzeigen
            </button>
            }


            <Modal show={showDescription} onHide={() => setShowDescription(false)} size="xl">
                <Modal.Header>
                    <h4>{item.title}</h4>
                </Modal.Header>
                <Modal.Body>
                    {item.description}
                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn btn-primary"} onClick={() => setShowDescription(false)}>Schliessen</button>
                </Modal.Footer>
            </Modal>


            <Modal show={showMostSimilar} onHide={() => setShowMostSimilar(false)} size="xl">
                <Modal.Header>
                    <h4>Ähnlichste Einträge</h4>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-sm table-responsive table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Kategorie</th>
                            <th scope="col">Ähnlichkeit</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mostSimilar != null && mostSimilar.map((e) => {
                            return (
                                <tr key={e.id}>
                                    <td>
                                        <a href={"#"}
                                           onClick={() => loadItem(e.id)}>{e.title}</a>
                                    </td>
                                    <td>{e.category}</td>
                                    <td>{Math.round(e.similarity * 100)}%</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn btn-primary"} onClick={() => setShowMostSimilar(false)}>Schliessen</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ItemDetails
