import Plot from 'react-plotly.js'
import React from 'react'
import useWindowDimensions from './WindowDimensions'

function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const PlotlyMap = ({ items, item, loadItem }) => {
  const { height, width } = useWindowDimensions()
  var layout = {
    // responsive: true,
    // useResizeHandler: true,
    // autosize: true,
    // width: '100%',
    // height: '100%',
    height: height - 150,
    xaxis: {
      title: '',
      showgrid: false,
      zeroline: false,
      showline: false,
      showticklabels: false,
    },
    yaxis: {
      title: '',
      showgrid: false,
      zeroline: false,
      showline: false,
      showticklabels: false,
    },
  }
  var traces = []
  var group = groupBy(items, (x) => x.category)

  for (let [key, value] of group) {
    let data = {
      x: value.map((x) => x.x),
      y: value.map((x) => x.y),
      ids: value.map((x) => x.id),
      text: value.map((x) => x.title),
      mode: 'markers',
      type: 'scatter',
      name: key,
      marker: {
        // color: coursesMap.color,
        size: 6,
      },
      hovertemplate: '%{text}',
    }
    traces.push(data)
  }

  if (item != null) {
    let data = {
      x: [item.x],
      y: [item.y],
      mode: 'markers',
      marker: {
        opacity: 1,
        color: 'rgba(0, 0, 0, 0)',
        line: {
          width: 1,
        },
        size: 10,
      },
      name: 'Ausgewählter Eintrag',
      hoverinfo: 'skip',
    }
    traces.push(data)
  }

  return (
    <div>
      <Plot
        data={traces}
        layout={layout}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        onClick={(data) => loadItem(data.points[0].id)}
      />
    </div>
  )
}

export default PlotlyMap
