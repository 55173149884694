import {useEffect, useState} from 'react'
import axios from '../Api'
import SearchIcon from '../assets/seach-icon.svg'
import SearchResultCard from '../components/SearchResultCard'
import Cookies from 'js-cookie';

import ItemDetails from '../components/ItemDetails'
import React from 'react';
import Select from 'react-select'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PlotlyMap from "../components/PlotlyMap";
import embeddingModel from '../components/Header'

const Overview = () => {
    const [showDetails, setShowDetails] = useState(false)

    const [categoriesOptions, setCategoriesOptions] = useState([])

    const [text, setText] = useState(null)
    const [categories, setCategories] = useState([])

    const [itemDetails, setItemDetails] = useState(null);
    const [mostSimilar, setMostSimilar] = useState(null)
    const [topics, setTopics] = useState(null)

    const [items, setItems] = useState([]);

    const getDataset = () => {
        return Cookies.get("dataset") !== undefined ? Cookies.get("dataset") : "FoodTech"
    }

    const getEmbeddingModel = () => {
        return Cookies.get("embeddingModel") !== undefined ? Cookies.get("embeddingModel") : "bert_embedding"
    }

    const searchItems = () => {
        axios.post("/items", {
            "dataset": getDataset(),
            "text": text,
            "categories": categories,
            "embedding_model": getEmbeddingModel(),
        }).then((res) => {
            setItems(res.data);
        }).catch((err) => {
            NotificationManager.error("Beim Laden des Eintrags ist ein Fehler aufgetreten.", 'Error');
            console.log(err);
        });
    }

    const loadItem = (id) => {
        axios.get("/item/" + id + "?embedding_model=" + getEmbeddingModel()).then((res) => {
            setItemDetails(res.data);
            setShowDetails(true);
        }).catch((err) => {
            NotificationManager.error("Beim Laden des Eintrags ist ein Fehler aufgetreten.", 'Error');
            console.log(err);
        });

        axios.get("/most_similar/" + id + "?embedding_model=" + getEmbeddingModel()).then((res) => {
            setMostSimilar(res.data);
        }).catch((err) => {
            NotificationManager.error("Beim Laden des Eintrags ist ein Fehler aufgetreten.", 'Error');
            console.log(err);
        });

        axios.get("/topics/" + id).then((res) => {
            if (res.data !== "") {
                setTopics(res.data);
            } else{
                setTopics(null);
            }
        }).catch((err) => {
            NotificationManager.error("Beim Laden des Eintrags ist ein Fehler aufgetreten.", 'Error');
            console.log(err);
        });
    }


    const loadCategories = () => {
        axios.get("/categories/" + getDataset()).then((res) => {
            setCategoriesOptions(res.data.map(x => ({value: x, label: x})));
        }).catch((err) => {
            NotificationManager.error("Beim Laden der Optionen ist ein Fehler aufgetreten.", 'Error');
            console.log(err);
        });
    }

    useEffect(() => {
        loadCategories();
        searchItems();
    }, []);


    const textChange = (e) => {
        setText(e.target.value);
    };

    const categoriesChange = (e) => {
        setCategories(e.map(x => x.value));
    };


    return (
        <div>
            <NotificationContainer/>
            <div className={"row"}>
                <div className={"col-md-2"}>
                    {items != null && items.length > 0 && <SearchResultCard items={items} loadItem={loadItem}/>}
                </div>
                <div className={"col-md-7"}>
                    <div className={"search"}>
                        <div className={"row"}>
                            <div className={"col-sm-8"}>
                                <label>Wonach suchst du?</label>
                                <input className={"form-control"} type={"text"} onChange={textChange}/>
                            </div>
                            <div className={"col-sm-3"}>
                                <label>Kategorien</label>
                                <Select options={categoriesOptions} isMulti onChange={categoriesChange}/>
                            </div>

                            <div className={"col-sm-1"}>
                                <button id={"btnSearch"} type={"button"} className={"btn search_btn"}
                                        onClick={searchItems}>
                                    <img src={SearchIcon} alt="search-icon"/>
                                </button>
                                <button id={"btnLoadCategories"} type={"button"} className={"d-none"}
                                        onClick={loadCategories}>Load
                                </button>
                            </div>
                        </div>
                    </div>

                    {items.length > 0 &&
                    <PlotlyMap items={items} item={itemDetails} loadItem={loadItem}/>}
                </div>
                <div className={"col-md-3"}>
                    {showDetails &&
                    <ItemDetails setShowDetails={setShowDetails} item={itemDetails}
                                 mostSimilar={mostSimilar} topics={topics}
                                 loadItem={loadItem}/>}
                </div>
            </div>
        </div>

    )
}

export default Overview
