const SearchResultCard = ({items, loadItem}) => {
    return (
        <div className="search_reasult_card">
            <ul>
                {items.map((e) => {
                    return (
                        <li key={e.id}>
                            <div className="search_tags">
                                <span>{e.category}</span>
                            </div>
                            <div className="search_heading">
                                <h3 onClick={() => loadItem(e.id)}>{e.title}</h3>
                                <a href={e.url} target={"_blank"}>
                                    <div className="img"></div>
                                </a>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default SearchResultCard
