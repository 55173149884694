import React, {useContext, useEffect, useState} from 'react'
import Header_Icon from '../assets/menu-icon.svg'
import CloseImg from '../assets/close.svg'
import Select from "react-select";
import Cookies from 'js-cookie';
import axios from "../Api";
import {NotificationManager} from "react-notifications";


const Header = () => {
    const [showNavigationSidebar, setShowNavigationSidebar] = useState(false)
    const [hideClass, setHideClass] = useState('')

    const [datasetOptions, setDatasetOptions] = useState([])
    const [dataset, setDataset] = useState(
        Cookies.get("dataset") !== undefined ? Cookies.get("dataset") : "survey_fasttext")

    const [model, setModel] = useState(
        Cookies.get("embeddingModel") !== undefined ? Cookies.get("embeddingModel") : "bert_embedding")
    const modelOptions = [
        {value: "bert_embedding", label: "BERT"},
        {value: "fasttext_embedding", label: "FastText"},
        {value: "roberta_embedding", label: "RoBERTa"}
    ]

    const modelChange = (e) => {
        setModel(e.value);
        Cookies.set("embeddingModel", e.value);
        // Hack to reload data
        document.getElementById("btnSearch").click();
    };

    const datasetChange = (e) => {
        setDataset(e.value);
        Cookies.set("dataset", e.value);
        // Hack to reload data
        document.getElementById("btnSearch").click();
        document.getElementById("btnLoadCategories").click();
    };

    const loadOptions = () => {
        axios.get("/datasets").then((res) => {
            setDatasetOptions(res.data.map(x => ({value: x, label: x})));
        }).catch((err) => {
            NotificationManager.error("Beim Laden der Optionen ist ein Fehler aufgetreten.", 'Error');
            console.log(err);
        });
    }

    useEffect(() => {
        loadOptions();
    }, []);

    const renderNavigationMenu = () => {
        return (
            <div className="navigation_sidebar">
                <div className="row" style={{justifyContent: 'space-between'}}>
                    <div className="col-8">
                        <h1>Semantic Similarity Map</h1>
                    </div>
                    <div className="col-2">
                        <button
                            onClick={() => {
                                setShowNavigationSidebar(false)
                                setHideClass('')
                            }}
                        >
                            <img src={CloseImg} alt=""/>
                        </button>
                    </div>
                </div>

                <label className="navigation_label">Datensatz</label>
                <Select options={datasetOptions} onChange={datasetChange}
                        value={datasetOptions.filter(({value}) => value === dataset)}/>

                <h4>Contact</h4>
                <span>Kevin Kuhn</span>
                <br/>
                <span>hello@kevinkuhn.ch</span>

                <p>
                    (c) 2021 Kevin Kuhn – <span>kevinkuhn.ch</span>
                </p>
            </div>
        )
    }

    return (
        <>
            <div className="navbar">
                <div className="container-fluid">
                    <div className="menu_left">
                        <h1>Semantic Similarity Map</h1>
                    </div>

                    <div className={`d-flex menu_right ${hideClass}`}>
                        <span>{dataset}</span>
                        <button
                            className="menu_btn"
                            onClick={() => {
                                setShowNavigationSidebar(true)
                                setHideClass('hideRightMenu')
                            }}
                        >
                            <img src={Header_Icon} alt="header icon"/>
                        </button>
                    </div>
                </div>
            </div>

            {showNavigationSidebar && renderNavigationMenu()}
        </>
    )
}

export default Header
